$(document).ready(function(){
    // Function to get the appropriate offset value based on screen size
    function getOffset() {
        if ($(window).width() < 576) {
            return 60; // Extra small devices
        } else if ($(window).width() >= 576 && $(window).width() < 767) {
            return 70; // Small devices
        } else if ($(window).width() >= 768 && $(window).width() < 991) {
            return 100; // Medium devices
        } else if ($(window).width() >= 992 && $(window).width() < 1199) {
            return 100; // Medium devices
        } else {
            return 110; // Large devices
        }
    }



    // Header Scroll
    let nav = document.querySelector("header");

    window.onscroll = function () {
        var scroll = document.documentElement.scrollTop;

        if (scroll >= 1) {
            nav.classList.add("header-scrolled");
        } else {
            nav.classList.remove("header-scrolled");
        }

        if (scroll > 100) {
            $('.scroll-to-top').fadeIn();
        } else {
            $('.scroll-to-top').fadeOut();
        }
    };

    // Click event to scroll to top
    $('.scroll-to-top').click(function(){
        $('html, body').animate({scrollTop : 0}, 100);
        return false;
    });

    // Intersection Observer to add active class to nav links
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const id = entry.target.getAttribute('id');
                $('.navbar a.nav-link').removeClass('active');
                $('.navbar a.nav-link[href="#' + id + '"]').addClass('active');
            }
        });
    }, { threshold: 0.1 });

    // Observe sections
    $('section').each(function(index, section) {
        observer.observe(section);
    });

    // Update offset on window resize
    $(window).resize(function() {
        $('body').scrollspy('refresh'); // Refresh Scrollspy
    });



    // One Page Nav
    var top_offset = $('#header').outerHeight();
    $('#nav').onePageNav({
        currentClass: 'current',
        scrollOffset: top_offset,
        scrollSpeed: 200,
    });
    $('#m-nav').onePageNav({
        currentClass: 'current',
        scrollOffset: top_offset,
        scrollSpeed: 200,
    });


      


});

